.extension {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  gap: 25px;

  &__field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__spin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__steps {
    // Custom step icons
    .custom-step-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      
      &-finish {
        background-color: @primary-color;
        color: white;
        border-radius: 50%;
        font-size: 1rem;
      }
      
      &-process {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border: 2px solid @primary-color;
        border-radius: 50%;
        
        &-inner {
          width: 12px;
          height: 12px;
          background-color: @primary-color;
          border-radius: 50%;
        }
      }
    }
  }
}

.oc-selection {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__title {
    font-size: 15px;

    &--plus {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}

.dimension-block {
  display: flex;
  gap: 5px;
  width: 100%;

  & > div {
    width: 100%;
  }
}

.product-step {
  &__title {
    display: flex;
    font-size: 24px;
    align-items: center;
    margin-bottom: 24px;

    &--left {
      font-weight: 700;
    }
  }
}

.product-step-dimension {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    font-size: 15px;
  }
}

.distribution-block {
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr 30px;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    gap: 16px;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr 30px;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    gap: 16px;

    > div {
      margin: 0 !important;
      // padding-left: 10px !important;

      & div {
        padding-bottom: 0 !important;
      }
    }
  }

  &__action-button {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: row;
    font-size: 14px;
    padding: 0 !important;

    &:hover {
      * > {
        color: #03729f;
      }
    }
  }

  &__action-icon {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 20px;
    color: rgba(95, 99, 104, 1);
    margin: 0 !important;
  }

  &__action-label {
    color: rgba(34, 34, 34, 0.6);
  }

  &__item-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item-content--icon {
    margin-left: 10px;
  }
}

@primary-color: #03729F;