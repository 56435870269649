@iconColor: #03729f;

.homepage {
  .ErrorCard-container {
    margin-bottom: 1rem;
  }
  .homepage__header--container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.094rem;
    align-items: center;
    color: #000000b2;
    .homepage__title {
      font-size: 20px;
      font-weight: 700;
      line-height: 27.28px;
      margin-bottom: 8px;
    }
    .homepage__subtitle {
      font-size: 16px;
      font-weight: 400;
      line-height: 21.82px;
      margin-bottom: 0px;
    }
  }

  .homepage__generic-card--charge {
    margin-bottom: 3rem !important;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    .GenericCard-container--information {
      gap: 0.5rem;
      .GenericCard-container--information--title {
        letter-spacing: 0.07px;
        color: #004662;
        font-size: 16px;
        line-height: 21.82px;
        font-weight: 700;
        margin-bottom: 0;
      }
      .GenericCard-container--information--description {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.1938rem;
        letter-spacing: 0.07px;
        position: absolute;
        left: 16px;
        margin-top: 9px;
      }
    }

    button {
      align-self: flex-end;
      margin: 0;
      border: 0;
      box-shadow: none;
      position: inherit;
      margin-top: 9px;
      span {
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.1938rem;
        letter-spacing: 0.07px;
        color: #03729f;
      }
    }
  }
  .homepage__other-recomendations {
    color: rgba(0, 0, 0, 0.7);
    font-size: 1rem;
    margin-bottom: 1rem;
    line-height: 1.3638rem;
  }
  .homepage__generic-card--charge,
  .homepage__generic-card--integration,
  .homepage__generic-card--credits {
    margin-bottom: 0.5rem;
    padding: 16px;
    transition: transform 0.3s ease-in-out;
    &:hover {
      transform: scale(1.005);
    }
    cursor: pointer;
    .GenericCard-container--information--title {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.1938rem;
      letter-spacing: 0.07px;
      margin-bottom: 0.5rem;
      color: #004662;
    }
  }
}

@media (max-width: 768px) {
  .homepage {
    margin: 1rem;
    .homepage__titles--container {
      margin-bottom: 1rem;
    }
    .homepage__header--container {
      flex-direction: column;
    }
    .AvailableQuotaCard-container {
      width: 100%;
    }
  }
}

.homepage__generic-card--container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: stretch;
  .featureFlag-children {
    flex: 1;
  }
  .homepage__generic-card--recomendation {
    height: 100%;
    display: flex;
    align-items: flex-start;
    .GenericCard-container--information {
      gap: 8px;
    }
  }
}
.homepage__generic-card--icon {
  color: @iconColor;
}
.homePage-btn-start {
  display: flex;
  flex-direction: row;
  gap: 12px;
  .arrow-start {
    color: #d32210 !important;
    font-size: 24px !important;
    font-weight: 400 !important;
  }
}

@primary-color: #03729F;