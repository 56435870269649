.hr-divider {
  height: auto;
  border: 1px solid #eeeeee;
  color: #000;
  background-color: #000;
}

.hr-mail-divider {
  border: 1px solid #eeeeee;
  color: #000;
  background-color: #000;
}
.EmailReception-header {
  .EmailReception-header-title {
    margin: 0;
  }
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
}
.container-form-email-reception {
  background-color: white;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  font-size: 13px;
  line-height: 17.73px;
  .ant-switch-checked {
    background-color: #3baa61;
  }
  [ant-click-animating-without-extra-node='true']::after {
    display: none;
  }
  .dynamic-form-container {
    overflow-y: auto;
    padding-left: 2px;
  }
}
.disabled-color {
  color: #aaaaaa;
}
.btn-getData {
  padding: 44px;
  margin: 0 auto;
  max-width: 90%;
}

.input-email {
  border-radius: 5px !important;
  width: 100%;
  padding-left: 16px;
}

.input-email.disabled {
  border: 1px solid #aaaaaa;
}

.disabled-info {
  color: #000;
}

.input-email-form {
  border-radius: 5px !important;
  padding-left: 16px;
}

.copy-icon {
  padding-left: 5px;
  align-items: center;
  display: flex;
  font-size: 16px;
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn-add {
  margin-top: 5px;
  border-radius: 5px;
  border: none;
  background: transparent;
  font-size: 18px;
}

.emails-form__buttons {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  max-width: 95%;

  .emails-form__buttons--flex {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
    margin-bottom: 30px;
  }
}

.btn-container {
  display: inline-block;
}

.email-dynamic-form-container-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.btn-container.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.label-title {
  margin-bottom: 10px !important;
}

.email-label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-new-add {
  text-align: right;
  margin: 0;
  border: none;
  box-shadow: none;
  font-size: 18px;
  background: transparent !important;
}

.btn-delete {
  text-align: center;
  padding: 4px 17px;
}

.btn-container-delete {
  display: flex;
  align-items: center;
}

.btn-save {
  border-radius: 5px;
}

.btn-save:not([disabled]) {
  background-color: #d32210 !important;
  color: white !important;
  border-color: transparent !important;
}

.btn-save:hover {
  opacity: 0.8;
}

.btn-save.disabled {
  opacity: 0.5;
}

.emailReception-copy-icon {
  font-size: 20px;
}

.message-info-disabled--title,
.message-info-disabled--text {
  margin-bottom: 0;

  a {
    color: black;
    text-decoration: underline;
  }
}

@primary-color: #03729F;